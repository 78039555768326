*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body{
    /* background-image: url('https://images.unsplash.com/photo-1724093121148-ec407f45e44c?q=80&w=1742'); */
    /* remove */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    min-height: 100vh;
    padding-top: 200px;
    /* background-color: #eaeaea; */
    /* removve end */
}
    /* .container{
        /* remove */
        /* width: 70vw; */
        /* margin:  auto 150px; */

        /* remove end */
        /* -webkit-backdrop-filter: blur(6px); */
        /* background-color: rgba(0,0,0,5); */
        /* backdrop-filter: blur(15px); */
        /* background-image: radial-gradient(#1e282d34 60%, #13171851 110%) */
        /* background-color: white; */
    /* } */

.container{
    width: 80vw;
    width: 1350px;
    /* margin: auto; */
    font-family: Poppins;
    height: 100%;
}
@media(max-width:1350px){
    body{
        display: unset;
    }
    .container{
        overflow-x: scroll;
        margin-top: 50px;
    }
}
.top:first-child{
    margin-bottom: 30px;
}
.container #logo{
    font-size: 50px;
    color: rgb(32, 46, 44);
    font-family: Arial, Helvetica, sans-serif;
}
#successMessage{
    text-align: center;
}
#successMessage.error{
    color: rgb(170, 76, 76) ;
}
#successMessage.success{
    color: rgb(104, 169, 81) ;
}
.container .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 20px;
}
.container .top .left,
.container .top .right{
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    /* justify-content: space-between; */
}

input[type="checkbox"]:hover{
    cursor: pointer;
}

.top .left>button,
.top .right>button{
    padding: 8px 25px;
    font-size: 16px;
    text-transform: capitalize;
    background-color: rgba(32, 46, 44, 0.123);
    /* font-weight: 600; */
    color: rgb(32, 46, 44);
    border: 1px solid rgb(32, 46, 44);
    transition: .3s;
    cursor: pointer;
    border-radius: 2em;

}

.top .left>button:hover,
.top .right>button:hover{
    background-color: rgb(32, 46, 44);
    color: white;
}
.top .left>button.main,
.top .right>button.main{
    background-color: rgba(32, 46, 44, 0.928);
    
    color: white;
}
.top .left>button.main:hover,
.top .right>button.main:hover{
    background-color: rgb(32, 46, 44);
}

/* Disabled button styling */
button:disabled {
  background-color: #bdc3c7;  /* Light grey background */
  color: #7f8c8d;             /* Dark grey text */
  cursor: not-allowed !important;        /* Show a 'not-allowed' cursor */
  opacity: 0.7;               /* Slightly faded appearance */
}



table{
    width: 100%;
    border-spacing: 0;
    margin-bottom: 50px;
}
thead{
    background-color: #e7e7e7;
}
th{
    font-size: 18px;
    text-align: left;
    /* border: 1px solid black; */
    font-weight: 400;
    text-transform: uppercase;
    /* background-color: #53616e9d; */
    padding: 8px 0;
    color: black;
    
}
thead th:first-child{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-align: center;
    border-top-left-radius: 12px;;
    border-bottom-left-radius: 12px;;
}
thead th:last-child{
    border-top-right-radius: 12px;;
    border-bottom-right-radius: 12px;;
}

thead th:first-child>input{
    margin: auto;
}
label{
    cursor: pointer;
}
td{
    padding: 18px 0;
    border:none;
    font-size: 16px;
    font-weight: 600;
    /* padding: 10px; */
    /* text-align: center; */
    /* border: 1px solid black; */
}
td:nth-child(3){
    max-width: 200px;
}
td>span.green{
    background-color: rgb(246, 255, 246);
    padding: 3px 5px;
}
td>span{
    padding: 3px 5px;
}
td>span.green{
    border: solid 2px rgba(54, 140, 54, 0.75);
    color: rgba(54, 140, 54, 0.75);
    border-radius: 2em;
    padding: 3px 15px;
}
td>span.red{
    /* background-color: rgba(129, 55, 55, 0.75); */
    border: solid 2px rgba(129, 55, 55, 0.75);
    background-color: rgba(255, 247, 247, 0.75);
    color: rgba(129, 55, 55, 0.75);
    border-radius: 2em;
    padding: 3px 15px;
}
table tr{
    transition: .15s;
}
table tr:hover{
    background-color: #f7f7f7;
    color: black;
}
table tr td:first-child{
    width: 40px;
    text-align: center;
}
    table tbody{
      color: black}
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    color: black;
    font-size: 18px;
}
/* table tr:hover{
    background-color: #4d698957; */
    /* backdrop-filter: blur(15px);
} */
table tr:not(:last-child) td{
    border-bottom: 1px solid #b6c5d4;
}
table tr td{
    /* background-color: red; */
    position: relative;
    text-transform: capitalize;
}

td button span{
    display: flex;
    align-items: center;
    gap: 3px;
}
table tr td button{
    color: black;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #aaa;
    border: none;
    cursor: pointer;
}

table tr td .popup{
    position: absolute;
    z-index: 2;
    background-color: rgba(222, 222, 222, 0.8) ;
    background-color: white;
    border: 1px solid #b7b7b7;
    backdrop-filter: blur(15px);
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 400px;
    right: 0;
    height: fit-content;
    max-height: 400px;
    top: 100%;
    /* overflow-y: scroll; */
}

table .popup h3{
    padding: 15px 25px;
}

table .popup .elements{
    height: calc(400px - 60px);
    overflow-y: scroll ;
}
table .popup .element{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    border-top: 1px solid #ababab;
    border-bottom: 1px solid #ababab;
}
table .popup .element>div:first-child{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

table .popup .element img{
    width: 50px;
}

table .popup .element .disc h4{
    font-size: 16px;
}

table .popup .element .disc li{
    list-style: none;
}
.pagination-controls{
    display: flex;
    gap: 0;
    justify-content: center;
}

.pagination-controls button{
    background-color: rgba(32, 46, 44, 0.123);
    border: 1px solid rgb(32, 46, 44);
    color: rgb(32, 46, 44);
    padding: 8px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s;
}

.pagination-controls button:hover{
    background-color: rgb(32, 46, 44);
    color: white;
}

.pagination-controls button:first-child{
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    padding-left: 15px;
}
.pagination-controls button:last-child{
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    padding-right: 15px;
}

.pagination-controls span{
    font-size: 18px;
    border-top: 1px solid rgb(32, 46, 44);
    border-bottom: 1px solid rgb(32, 46, 44);
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
}
@keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  .popup-modal {
    position: fixed;
    bottom: 20px;
    font-size: 18px;
    right: 20px;
    padding: 15px;
    border: 2px solid;
    border-radius: 1em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    width: 250px;
    text-align: center;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease-in-out;
    animation: slideUp 0.4s ease-out;
  }

  .popup-modal.success{
    background-color: #d4e4d3;
    border-color: #b2d7a4;
    color: #459045;
  }
  .popup-modal.error{
    background-color: #e4d3d3;
    border-color: #d7a4a4;
    color: #904545;
  }
  
  .popup-modal.hidden {
    animation: slideDown 0.4s ease-out;
    opacity: 0;
    pointer-events: none;
  }
  