
.form-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.body.login>h1{
    text-transform: capitalize;
    color: rgb(32, 46, 44);
    text-align: center;
    margin-bottom: 30px;
}

/* end Global rules */

/* log in */

/* .body{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/nora-jane-long-ALyaAQ8nvF0-unsplash.jpg"); */
    /* background-color: aquamarine;
    background-size: cover;
    background-position: center;
} */

.form-container{
    background-image: radial-gradient(rgba(32, 46, 44, 0.083) 60%, #13171815 110%);
    border: 2px solid rgb(32, 46, 44);
    border-radius: 10px;
    backdrop-filter: blur(15px);
    padding-top: 30px;
    padding-bottom: 30px;
    width: 350px;
}

.form-container form input{
    padding: 10px 15px;
    border: 1px solid rgb(32, 46, 44);
    border-radius: 2em;
    margin-bottom: 10px;
    font-size: 18px;
    background-color: #fffffffc;
    color: black;
    width: 100%;
}

.form-container form input:focus{
    outline: none;
    background-color: #fafafa;
}

.form-container form button{
    width: 100%;
    padding: 10px 10px;
    border-radius: 2em;
    border: 1px solid #324158;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    cursor: pointer;
    color: white;
    background-color: rgba(32, 46, 44, 0.9);
    transition: .3s;
}

.form-container form button:hover{
    background-color: rgb(32, 46, 44);
    color: white;
    
}

.form-container form a {
    text-align: center;
    display: block;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    text-decoration: underline;
}

@media (max-width: 500px){
    .form-container{
        width: 100%;
    }
}

.alert{
    background-color: rgb(138, 8, 8);
    color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.sign-in{
    width: 100%;
    padding: 10px 10px;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    cursor: pointer;
    color: white;
    text-align: center;
    background-color: black;
}